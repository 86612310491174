module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.memberships.mmtdigital.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"memberships.mmtdigital.co.uk","short_name":"memberships.mmtdigital.co.uk","start_url":"/","background_color":"#ffffff","theme_color":"#225885","display":"standalone","icon":"src/assets/mmt-logo-small.png","include_favicon":true,"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5ZGDXBF","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
